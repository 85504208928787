export class SearchDTO {
	pageNo: number = 0;
	pageSize: number = 10;
	search: string;
	totalSize: number = 0;
    ids: any;
    Reload: boolean = false;
    Scroll: boolean = false;
    index: any;
}
