import { IMenuItem } from "./navigation.model";

export let buywoodMenu: IMenuItem[] = [
  {
    module: ["Dashboard"],
    name: "Dashboard",
    type: "link",
    state: "dashboard",
  },

  {
    module: ["Product"],
    name: "Catalogue",
    type: "dropDown",
    state: "",
    sub: [
      {
        name: "Passportsize photo",
        module: ["Passportsize photo"],
        state: "passportsize-photo",
      },
      { name: "Photo print", module: ["Photo print"], state: "photo-print" },
      { name: "Photoframe", module: ["Photoframe"], state: "Photoframe" },
      {
        name: "Personalized Products",
        module: ["Personalized Products"],
        state: "product-catalogue",
      },
      {
        name: "Ecommerce Products",
        module: ["E-Commrece"],
        state: "e-commrece-product",
      },
      {
        name: "Selfie Album",
        module: ["selfie-album"],
        state: "selfie-album",
      },
    ],
  },

  // {
  //   module:['Passportsize photo'],
  //   name: " Passportsize photo",
  //   type: "link",
  //   state: "passportsizephoto"
  // },
  // {
  //   module:['Photoprint'],
  //   name: "Photoprint",
  //   type: "link",
  //   state: "Photoprint"
  // },

  // {
  //   module:['Photoframe'],
  //   name: "Photoframe",
  //   type: "link",
  //   state: "Photoframe"
  // },

  {
    name: "Orders",
    type: "dropDown",
    module: [
      "Waiting Payments",
      "Waiting Dispatch",
      "Dispatch Details",
      "Cancelled Details",
    ],
    state: "",
    sub: [
      { name: "Waiting COD", module: ["Waiting COD"], state: "waiting-cod" },
      // { name: "Billing Management", module: ["Billing Management"], state: "billing-management" },

      {
        name: "Waiting Payments",
        module: ["Waiting Payments"],
        state: "waiting-payments",
      },
      {
        name: "Waiting Dispatch",
        module: ["Waiting Dispatch"],
        state: "waiting-dispatch",
      },
      // { name: "Dispatch Details", module: ["Dispatch Details"], state: "dispatch-details" },
      {
        name: "Delivery Details",
        module: ["Delivery Details"],
        state: "delivery-details",
      },
      {
        name: "Cancelled Details",
        module: ["Cancelled Details"],
        state: "cancelled-details",
      },
      {
        name: "Billing Management",
        module: ["Billing Management"],
        state: "billing-management",
      },
      // {
      //   name: "Job Card Creation",
      //   module: ["Job-Card-Creation"],
      //   state: "Job-Card-Creation",
      // },
      // {
      //   name: "Invoice Generation",
      //   module: ["Invoice-Generation"],
      //   state: "Invoice-Generation",
      // },
      // {
      //   name: "Order History",
      //   module: ["Order-History"],
      //   state: "Order-History",
      // },

      { name: "Track Order", module: ["Track Order"], state: "track-order" },
    ],
  },

  {
    module: ["Customer Details"],
    name: "Customers",
    type: "link",
    state: "customer-details",
  },

  {
    module: ["employee"],
    name: "Employee",
    type: "link",
    state: "employee-details",
  },
  {
    module: ["task-manager"],
    name: "Task Manager",
    type: "link",
    state: "task-manager",
  },

  {
    module: ["Commission Transaction"],
    name: "Transactions",
    type: "link",
    state: "commission-transaction",
  },
  {
    module: ["refund"],
    name: "Refund",
    type: "link",
    state: "refund",
  },

  {
    name: "CMS",
    type: "dropDown",
    module: ["Banner", "Video", "Certificate", "Greetings"],
    state: "",
    sub: [
      { name: "Banner", module: ["Banner"], state: "cms/banner" },
      { name: "Video", module: ["Video"], state: "cms/video" },

      { name: "Greetings", module: ["Greetings"], state: "cms/greeting" },
    ],
  },
  {
    name: "Contest",
    type: "link",
    module: ["Selfie-contest"],
    state: "contest",
  },
  {
    name: "Ticket",
    type: "link",
    module: ["Ticket"],
    state: "ticket",
  },

  {
    name: "Management Communication",
    type: "link",
    module: ["Management-Communication"],
    state: "manangenet-communication",
  },

  // {
  //   module:['Leads'],
  //   name: "Leads",
  //   type: "link",
  //   state: "leads"
  // },

  {
    name: "Reports",
    type: "dropDown",
    module: [
      "Sales Reports",
      "Stock Reports",
      "Payment Transaction Reports",
      "Dealer Payout Reports",
    ],
    state: "",
    sub: [
      {
        name: "Order Report",
        module: ["Sales Reports"],
        state: "reports/sales-report",
      },
      {
        name: "Refund Report",
        module: ["refund report"],
        state: "reports/refund-report",
      },
      {
        name: "Tickets Report",
        module: ["ticket report"],
        state: "reports/ticket-report",
      },
      {
        name: "GST Report",
        module: ["gst report"],
        state: "reports/gst-report",
      },
      {
        name: "task Report",
        module: ["task report"],
        state: "reports/task-report",
      },
      {
        name: "ratings review Report",
        module: ["ratings review report"],
        state: "reports/ratings-review-report",
      },
      {
        name: "customer Report",
        module: ["Customer report"],
        state: "reports/customer-report",
      },
      {
        name: "employee Report",
        module: ["Employee report"],
        state: "reports/employee-report",
      },
      {
        name: "product Report",
        module: ["Product report"],
        state: "reports/product-report",
      },

      {
        name: "Stock Report",
        module: ["Stock Reports"],
        state: "reports/stock-report",
      },
      {
        name: "Payment Transaction Report",
        module: ["Payment Reports"],
        state: "reports/payments",
      },
      // {
      //   name: "Dealer Payout Report",
      //   module: ["Dealer Payout Reports"],
      //   state: "reports/dealerpayout-report",
      // },
    ],
  },

  {
    name: "Settings",
    type: "link",
    // module:['Wood purpose','COD','Category','Delivery Charge','Shipped Vendor','Consultation Fee','User','Role','Permission','Change Password','Activity Log'],
    module: ["Expected Delivery Days", "Change Password", "QR Code"],
    state: "settings",
    sub: [
      // { name: "Settings", type: 'link', module:['Brand'], state: "settings/brand" },
      {
        name: "Settings",
        type: "link",
        module: ["Category"],
        state: "settings/category-subcategory",
      },
      // { name: "Settings", type: 'link', module:['COD'], state: "settings/cod" },
      // { name: "Settings", type: 'link', module:['Wood purpose'], state: "settings/wood" },
      // { name: "Settings", type: 'link', module:['Consultation Fee'], state: "settings/consultation-fees" },
      // { name: "Settings", type: 'link', module: ['User'], state: "settings/users" },
      // { name: "Settings", type: 'link', module: ['Role'], state: "settings/role" },
      // { name: "Settings", type: 'link', module:['Permission'], state: "settings/role-permission" },
      {
        name: "Settings",
        type: "link",
        module: ["Expected Delivery Days"],
        state: "settings/expected-delivery",
      },

      {
        name: "Settings",
        type: "link",
        module: ["Change Password"],
        state: "settings/change-password",
      },
      {
        name: "Settings",
        type: "link",
        module: ["QR Code"],
        state: "settings/qr-code",
      },
      {
        name: "Settings",
        type: "link",
        module: ["department"],
        state: "settings/department",
      },
      {
        name: "Settings",
        type: "link",
        module: ["photoprinting"],
        state: "settings/photo-printing",
      },
      {
        name: "Settings",
        type: "link",
        module: ["gstpercentage"],
        state: "settings/gst-percentage",
      },
      {
        name: "Settings",
        type: "link",
        module: ["couponcode"],
        state: "settings/couponcode",
      },
      {
        name: "Settings",
        type: "link",
        module: ["varianttype"],
        state: "settings/varianttype",
      },

      {
        name: "Settings",
        type: "link",
        module: ["Activity Log"],
        state: "settings/activity-logs",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Activity Log"],
        state: "settings/other-district",
      },
      // { name: "Settings", type: 'link', module:['Delivery Charge'], state: "settings/delivery-charge" },
      // { name: "Settings", type: 'link', module:['Shipped Vendor'], state: "settings/shipped-vendor" },
      // { name: "Settings", type: 'link', module:['Ratings & Review'], state: "settings/ratings-review" },
    ],
  },
];

export let buywoodExpEmployeeMenu: IMenuItem[] = [
  {
    module: ["Enquiry"],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries",
  },
];

export let buywoodEmployeeMenu: IMenuItem[] = [
  {
    module: ["Enquiry"],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries",
  },

  {
    module: ["Leads"],
    name: "Leads",
    type: "link",
    state: "employee/leads",
  },
];
