import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { decodedToken } from 'app/core/helpers/token.helper';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { ToastService } from 'app/core/services/toast.service';
import { UserService } from 'app/core/services/user.service';
import { NotificationService } from 'app/modules/admin/notification/service/notification.service';
import { NotificationsService } from 'app/modules/employee/notification/service/notification.service';
import { get } from 'lodash';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  providers: [MessagingService]
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;



  // Dummy notifications
  notifications
  portalType: any;
  notificationsList: any;
  employeeType: any;
  count: any;
  token: any;

  // constructor(private router: Router,
  //   private modelService:NotificationService) {}



  constructor(private router: Router,
    private modelService: NotificationService,
    private employeeService: NotificationsService,
    private loader: AppLoaderService,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private toastr: ToastService,
    private userService: UserService

  ) {
    this.reloadNotify()
    this.removeNotificationBasedOnId();
    this.getCurrentNotification()
  }


  ngOnInit() {
    this.token = decodedToken();
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });

    this.modelService.notificationList$.subscribe(res => {
      if (res) {
        this.getAllNotification();
      }
    });

    this.userService.currentUser.subscribe((current: any) => {
      const token = window.localStorage.getItem('PRINTAPPADMIN');
      if (token) {
        this.messagingService.receiveMessage();
      }
    });

    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
    this.employeeType = get(token, 'employee_type') ?? '';
    if (this.portalType === PORTALTYPE.ADMIN) {
      this.getAllNotification()
    } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
      if (this.employeeType == 1) {
        this.getWoodExpertNotification()
      } else if (this.employeeType == 2) {
        this.getTeleNotification()
      }
    }

  }



  reloadNotify() {
    this.messagingService.reloadNotificationList.subscribe(res => {
      if (res) {
        if (this.portalType === PORTALTYPE.ADMIN) {
          this.getAllNotification()
        } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
          if (this.employeeType == 1) {
            this.getWoodExpertNotification()
          } else if (this.employeeType == 2) {
            this.getTeleNotification()
          }
        }
      }
    })
  }

  getCurrentNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res) {
        console.log(res);
        this.notifications.unshift(res);
      }
    })
  }

  removeNotificationBasedOnId() {
    this.messagingService.removeMessage.subscribe(res => {
      if (res) {
        console.log(res);
        let index = this.notifications.findIndex(el => el.notification_id == res.notification_id);
        this.notifications.splice(index, 1);
      }
    })
  }


  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }

  getAllNotification() {
    this.modelService.getAllNotification().subscribe(res => {
      if (res.keyword == 'success') {
        this.notifications = res?.data;
        var getcurrentdata: any = localStorage.getItem('NotificationList' + `${this.token.acl_user_id}`);
        let currentdata: any[] = getcurrentdata ? JSON.parse(getcurrentdata) : [];
        if (currentdata.length == 0) {
          localStorage.setItem('NotificationList' + `${this.token.acl_user_id}`, JSON.stringify(res?.data));
          currentdata = res.data;
        } else if (res.data.length >= currentdata.length) {
          res.data.forEach((element: any, index: number) => {
            currentdata.filter((currentelement) => {
              if (currentelement != undefined && element?.notification_id == currentelement?.notification_id) {
                if (currentelement.msg_read == 1) {
                  res.data[index].msg_read = 1;
                }
              }
            });
          });
          currentdata = [];
          currentdata = res.data;
        }
        localStorage.setItem('NotificationList' + `${this.token.acl_user_id}`, JSON.stringify(currentdata));
        this.notifications = currentdata.filter((res: any) => res.msg_read == 0) ? currentdata.filter((res: any) => res.msg_read == 0) : [];
        this.generateRouteService.setQueueCount(currentdata.filter((res: any) => res.msg_read == 0).length, true);
        localStorage.setItem('queueCount', JSON.stringify(currentdata.filter((res: any) => res.msg_read == 0).length));
        this.count = this.notifications.length;
      }
      else {
        localStorage.setItem('queueCount', JSON.stringify(0));
        localStorage.setItem('NotificationList' + `${this.token.acl_user_id}`, JSON.stringify([]));
        this.generateRouteService.setQueueCount(0, true);
      }
    })
  }

  getTeleNotification() {
    this.employeeService.getTeleNotificationList().subscribe(res => {
      this.notifications = res.data.filter(el => el.msg_read == 0);
      this.count = this.notifications.length;
      this.generateRouteService.setQueueCount(this.count, true);
    })
  }

  getWoodExpertNotification() {
    this.employeeService.getWoodExpertNotification().subscribe(res => {
      this.notifications = res.data.filter(el => el.msg_read == 0);
      this.count = this.notifications.length;
      this.generateRouteService.setQueueCount(this.count, true);
    })
  }
  viewMore() {
    if (this.portalType === PORTALTYPE.ADMIN) {
      this.router.navigate(['/notification/list']);
    } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
      this.router.navigate(['/employee/notification/list']);
    }

  }


  clicked(index, row) {
    let obj = {
      id: JSON.stringify([row.notification_id]),

    }

    if (this.portalType === PORTALTYPE.ADMIN) {
      // this.modelService.setReadStatus(obj)
      //   .subscribe(res => {
      //   })
      let finalData: any = localStorage.getItem('NotificationList'+`${this.token.acl_user_id}`);
      finalData = finalData ? JSON.parse(finalData) : this.notifications;
      if (this.notifications.find((res: any) => res.notification_id == row.notification_id)?.msg_read == 0) {
        this.notifications[index].msg_read = 1;
        let arrayIndex = finalData.findIndex((res: any) => Number(res.notification_id) == Number(row.notification_id))
        finalData[arrayIndex].msg_read = 1;
        localStorage.setItem('NotificationList'+`${this.token.acl_user_id}`, JSON.stringify(finalData));
        this.generateRouteService.setQueueCount(finalData.filter((res: any) => res.msg_read == 0).length, true);
        localStorage.setItem('queueCount', JSON.stringify(finalData.filter((res: any) => res.msg_read == 0).length));
      }
      // this.notifications.splice(index, 1);
      this.notifications = [];
      this.notifications = finalData.filter((res: any) => res.msg_read == 0);
      this.count = this.notifications.length;
      this.messagingService.appendQueueCount(0);
      this.modelService.setNotificationList(finalData.length, true);
      this.generateRouteService.navigateRouteForGym(row.portal, row.page, row.data);
    } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
      if (this.employeeType == 1) {
        this.employeeService.setWoodReadStatus(obj)
          .subscribe(res => {
          })
        this.notifications.splice(index, 1);
        this.messagingService.appendQueueCount(0);

      } else if (this.employeeType == 2) {
        this.employeeService.setTeleReadStatus(obj)
          .subscribe(res => {
          })
        this.notifications.splice(index, 1);
        this.messagingService.appendQueueCount(0);
      }


    }
    // if (this.portalType === PORTALTYPE.ADMIN) {
    //   this.generateRouteService.navigateRouteForGym(row.portal, row.page, row.data);
    // } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
    //   this.generateRouteService.navigateRouteForDietitian(row.portal, row.page, row.data);
    // } 
  }
}