export const environment = {
  production: false,
  // apiURL: 'https://printappapi.technogenesis.in/api/V1/AP/', //for UI
  apiURL:`https://www.technogenesis.in/printappdevapi/api/V1/AP/`, //for developers
  EmpapiURL: "https://printappapi.technogenesis.in/api/V1/AP/",
  firebaseConfig: {
    apiKey: "AIzaSyCqC8aUDVAWLbrAij4KCxK3gc0-sKkqLpI",
    authDomain: "nr-info.firebaseapp.com",
    projectId: "nr-info",
    storageBucket: "nr-info.appspot.com",
    messagingSenderId: "220293232300",
    appId: "1:220293232300:web:bf3d120c32138432dc9109",
    measurementId: "G-7K3Z26LQDT",
  },
};
