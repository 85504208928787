<div class="text-center">
    <h6 class="m-8 p-4">{{ title }}</h6>
    <div mat-dialog-content [style.width]="'50'">
        <!-- <mat-spinner [style.margin]="'auto'" [style.overflow-x]="'hidden'"></mat-spinner> -->
        <!-- <img src="assets/images/loader-new.gif" width="100" height="100" alt=""> -->
        <img src=" https://i.pinimg.com/originals/07/24/88/0724884440e8ddd0896ff557b75a222a.gif" width="100" height="100" alt="">
       
    </div>
</div>

<!-- <div fxLayout="row wrap" class=" mt-16">
    <div fxFlex="100" fxFlex.gt-xs="100" class="text-center">
        <img src="assets/images/loader.gif" width="200" height="200" alt="logo" class="" />
    </div>
</div> -->