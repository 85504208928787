import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from 'app/shared/shared-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TgssSelectBoxComponent } from './tgss-select-box.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';


@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MatSelectInfiniteScrollModule
  ],
  declarations: [TgssSelectBoxComponent],
  exports: [TgssSelectBoxComponent],
  providers: []
})
export class TgssSelectBoxModule { }
